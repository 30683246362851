<!--
  修改密码
    + 一般机构或者服务机构
 -->
<template>
  <div class="detail page_min_width">
    <detail-header title="修改密码" />
    <div class="detail_main page_width">
      <div class="main_content">
        <el-form
          class="form"
          ref="form"
          :model="form"
          :rules="rules"
          label-width="140px"
          label-position="left"
          size="small">
          <el-form-item prop="oldPassword" label="原密码">
            <el-input
              type="password" show-password
              v-model.trim="form.oldPassword"
              placeholder="请输入原密码">
            </el-input>
          </el-form-item>
          <el-form-item prop="newPassword" label="新密码">
            <el-input
              type="password" show-password
              v-model.trim="form.newPassword"
              placeholder="请输入新密码">
            </el-input>
          </el-form-item>
          <el-form-item prop="confirmPassword" label="确认密码">
            <el-input
              type="password" show-password
              v-model.trim="form.confirmPassword"
              placeholder="请确认新密码">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" :loading="submitBtnLoading" @click="submitForm">修改密码</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import SHA256 from 'crypto-js/sha256'
export default {
  data () {
    const confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请确认密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      // 登录的button  loading
      submitBtnLoading: false,
      form: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      // 登录表单验证规则
      rules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,16}$/, message: '密码长度为8~16位，至少需要包含数字、字母两种类型', trigger: 'blur' },
          { validator: confirmPassword, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.updateOrgPassword()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    updateOrgPassword () {
      this.submitBtnLoading = true
      api.updateOrgPassword({
        oldPassword: SHA256(this.form.oldPassword).toString(),
        newPassword: SHA256(this.form.newPassword).toString(),
        confirmPassword: SHA256(this.form.confirmPassword).toString()
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('修改成功！')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        this.$message.error('请求出错')
      }).finally(() => {
        this.submitBtnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 760px;
      margin: 0 auto;
    }
  }

}
</style>
